.alert-container-success,
.alert-container-loading,
.alert-container-error {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	bottom: 20px;
	left: 30%;
	right: 30%;
	height: 50px;
	border-radius: 5px;
}

.alert-container-success {
	border: 1px solid #bfded0;
	background-color: #d1e7dd;
	color: #366160;
}

.alert-container-loading {
	border: 1px solid #bbd7fe;
	background-color: #cfe2ff;
	color: #2455a9;
}

.alert-container-error {
	border: 1px solid #f6c7cb;
	background-color: #f8d7da;
	color: #922e3f;
}
