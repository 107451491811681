.device-tree-container {
	width: 300px;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
}

.button-container-custom {
	display: flex;
	height: 50px;
	width: 100%;
	background-color: transparent;
	background-repeat: no-repeat;
	color: var(--main-color);
	border: none;
	border-radius: 5px;
	cursor: pointer;
	align-items: center;
}

.button-container-custom:hover {
	background-color: #ebebeb;
}

.arrow-button-custom,
.arrow-gateway-button-custom,
.arrow-datalogger-button-custom,
.arrow-sensor-button-custom,
.redirect-button-custom,
.redirect-sensor-button-custom {
	display: flex;
	height: 50px;
	background-color: transparent;
	background-repeat: no-repeat;
	color: var(--main-color);
	border: none;
	align-items: center;
	cursor: pointer;
}

.arrow-gateway-button-custom {
	margin-left: 10px;
}

.arrow-datalogger-button-custom {
	margin-left: 20px;
}

.arrow-sensor-button-custom {
	margin-left: 30px;
}

.redirect-button-custom {
	width: 100%;
}

.redirect-sensor-button-custom {
	width: 100%;
	margin-left: 50px;
}

.device-tree-icon-style {
	margin-left: 20px;
	margin-right: 10px;
}

.device-tree-text-style {
	font-size: 16px;
}

.collapse-button {
	display: flex;
	margin-right: 20px;
	height: 40px;
	width: 100px;
	background-color: var(--main-color);
	color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	justify-content: center;
	align-items: center;
}
