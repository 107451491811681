.page {
	margin-top: 60px;
	margin-right: 20px;
}

/* Lists container */
.loading-container {
	display: flex;
	justify-content: center;
}

.table-container {
	flex: 1;
	margin: 20px 20px 0px 20px;
	justify-content: center;
	z-index: 100px;
}

.buttons-container {
	flex: 1;
	margin-bottom: 10px;
	display: flex;
	width: 100%;
	flex-direction: row-reverse;
	align-items: center;
}

.admin-container {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
}

.admin-title {
	color: var(--main-color);
	font-size: 14px;
	font-weight: 500;
	margin-top: 15px;
}

.admin-input {
	border: none;
	border-bottom: 1px solid var(--main-color);
	margin-top: 10px;
	outline: none;
	width: 300px;
}

.admin-input-disabled {
	border: none;
	border-bottom: 1px dotted var(--main-color);
	margin-top: 10px;
	outline: none;
	width: 300px;
	pointer-events: none;
}

.item-style {
	margin: 0px 10px 0px 0px;
	accent-color: var(--main-color);
}

.item-text {
	font-size: 14px;
	margin-top: 15px;
}

.checkbox-container {
	display: flex;
	flex-direction: row;
	padding-top: 15px;
	padding-bottom: 15px;
}

.checkbox-text {
	font-size: 14px;
}

.checkbox-style {
	margin: 0px 10px 0px 0px;
	accent-color: var(--main-color);
}

/* ----------------------- Device-Models Params and Soil Params ----------------------- */
.device-model-params {
	border: none;
	border-bottom: 1px solid var(--main-color);
	margin-top: 10px;
	outline: none;
	width: 100px;
}

.soil-params {
	border: none;
	border-bottom: 1px solid var(--main-color);
	margin-top: 10px;
	outline: none;
	width: 90px;
}

.param-container {
	margin: 10px 0px 10px 0px;
}

.param-text {
	font-size: 14px;
}

/* ----------------------- Password Button ----------------------- */
.change-password-button {
	width: 305px;
	margin-top: 30px;
	border: none;
	border-bottom: 1px solid var(--main-color);
	background-color: transparent;
	color: var(--main-color);
}

.style-password-button {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.right-container {
	display: flex;
	flex: 1;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
}

/* ----------------------- Tab Navigation ----------------------- */
ul.nav {
	width: 100%;
	display: flex;
	align-items: center;
	/* justify-content: space-between; */
	border-bottom: 1px solid #d6d6d6;
	padding-left: 0px;
	@media (max-width: 300px) {
		width: 90%;
	}
}
ul.nav li {
	padding: 1rem;
	list-style: none;
	text-align: center;
	cursor: pointer;
	transition: all 0.3s;
}
ul.nav li:first-child {
	border-radius: 0;
}

ul.nav li:last-child {
	border-radius: 0;
}
ul.nav li:hover {
	border-bottom: 2px solid var(--main-color);
}
ul.nav li.active {
	border-bottom: 2px solid var(--main-color);
	color: var(--main-color);
	font-weight: bold;
	transition: all 0.3s;
}
