.dropdown-container {
	border: none;
	border-bottom: 1px solid var(--main-color);
	margin-top: 10px;
	outline: none;
}

.dropdown-input {
	padding: 5px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	user-select: none;
}

.dropdown-menu {
	position: absolute;
	transform: translateY(4px);
	border: 1px solid #ccc;
	border-radius: 5px;
	overflow: auto;
	max-height: 150px;
	background-color: #fff;
	z-index: 1;
}

.dropdown-item {
	padding: 5px;
	cursor: pointer;
}

.dropdown-item:hover {
	background-color: rgb(243, 243, 243);
}

.dropdown-item.selected {
	background-color: rgba(7, 39, 26, 0.548);
	color: #ffffff;
}
